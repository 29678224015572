@tailwind base;
@tailwind components;
@tailwind utilities;

.rotate-360 {
    transform: rotate(360deg);
    transition: transform 0.5s ease-in-out;
  }


  